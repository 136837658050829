var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeLayout == "administrative"
    ? _c(
        "div",
        {
          attrs: { id: "sidebar-area", isOpen: _vm.isOpen ? "true" : "false" },
        },
        [
          _c(
            "div",
            {
              staticClass: "menu-control-top",
              on: {
                click: function ($event) {
                  _vm.isOpen = !_vm.isOpen
                },
              },
            },
            [_c("i", { staticClass: "fa fa-chevron-circle-left" })]
          ),
          (_vm.activeLayout == "dev" || _vm.activeLayout == "administrative") &&
          ![null, "Login", "2FASetup", "2FAVerify"].includes(_vm.$route.name)
            ? _c("aside", { staticClass: "menu" }, [
                _c(
                  "div",
                  {
                    staticClass: "menu-control-bottom",
                    on: {
                      click: function ($event) {
                        _vm.isOpen = !_vm.isOpen
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "fa-svg" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 448 512",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M400 480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zM252.9 360.4L177.4 288H360c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H177.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L75.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" Collapse Menu "),
                  ]
                ),
                _c("ul", { staticClass: "menu-list" }, [
                  _c(
                    "li",
                    [
                      _c(
                        "nav-link",
                        {
                          attrs: {
                            to: { name: "staffDashboard" },
                            linkClass: "has-text-white",
                          },
                        },
                        [
                          _c("span", [_c("i", { staticClass: "fas fa-home" })]),
                          _c("span", [_vm._v(" Dashboard ")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.activeLayout == "administrative" &&
                _vm.$hasPermissions(
                  _vm.clientSession,
                  [
                    "EMAIL_TEMPLATES",
                    "CLIENT_REPORTS",
                    "DOCUMENT_CENTER",
                    "ANNOUNCEMENTS",
                    ,
                    "ASSET_REPORT",
                    "ASSET_SUMMARY_REPORT",
                    "C8925_REPORT",
                    "ANNUAL_QUESTIONNAIRE",
                    "ANNUAL_BOLI_REVIEW",
                  ],
                  1
                )
                  ? _c("p", { staticClass: "menu-label" }, [
                      _c("span", [_vm._v("Operations")]),
                    ])
                  : _vm._e(),
                _c(
                  "ul",
                  { staticClass: "menu-list" },
                  [
                    _c(
                      "has-permissions",
                      { attrs: { permissions: [["CLIENT_REPORTS", 1]] } },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "Reports" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "padding-right": "1px" } },
                                  [_c("i", { staticClass: "fa fa-file-alt" })]
                                ),
                                _c("span", [_vm._v(" Client Reports ")]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.$hasPermissions(_vm.clientSession, ["ASSET_REPORT", 1])
                      ? _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "AssetReporting" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "padding-right": "1px" } },
                                  [_c("i", { staticClass: "fa fa-file-alt" })]
                                ),
                                _c("span", [_vm._v(" Asset Report ")]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$hasPermissions(_vm.clientSession, [
                      "ASSET_SUMMARY_REPORT",
                      1,
                    ])
                      ? _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "AssetSummaryReporting" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "padding-right": "1px" } },
                                  [_c("i", { staticClass: "fa fa-file-alt" })]
                                ),
                                _c("span", [_vm._v(" Summary Asset Report ")]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$hasPermissions(_vm.clientSession, ["C8925_REPORT", 1])
                      ? _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "8925Reporting" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "padding-right": "1px" } },
                                  [_c("i", { staticClass: "fa fa-file-alt" })]
                                ),
                                _c("span", [_vm._v(" 8925 Report ")]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$hasPermissions(_vm.clientSession, [
                      "ANNUAL_QUESTIONNAIRE",
                      1,
                    ])
                      ? _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "AnnualQuestionnaire" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "padding-right": "1px" } },
                                  [_c("i", { staticClass: "fa fa-file-alt" })]
                                ),
                                _c("span", [_vm._v(" Annual Questionnaires ")]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$hasPermissions(_vm.clientSession, [
                      "ANNUAL_BOLI_REVIEW",
                      1,
                    ])
                      ? _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "AnnualBOLIReviewListPage" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "padding-right": "1px" } },
                                  [_c("i", { staticClass: "fa fa-file-alt" })]
                                ),
                                _c("span", [
                                  _vm._v(
                                    " Annual " +
                                      _vm._s(_vm.coliOrBoli) +
                                      " Review "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$hasPermissions(
                      _vm.clientSession,
                      ["DOCUMENT_CENTER"],
                      1
                    )
                      ? _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "DocumentsCenterScreen" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c("span", [
                                  _c("div", { staticClass: "fa-svg" }, [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 448 512",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M0 464C0 490.4 21.6 512 48 512h352c26.4 0 48-21.6 48-48v-192H0V464zM128 368C128 359.1 135.1 352 144 352h160c8.875 0 16 7.125 16 16v40c0 4.375-3.625 8-8 8h-16C291.6 416 288 412.4 288 408V384H160v24C160 412.4 156.4 416 152 416h-16C131.6 416 128 412.4 128 408V368zM400 0h-352C21.6 0 0 21.6 0 48v192h448v-192C448 21.6 426.4 0 400 0zM320 152C320 156.4 316.4 160 312 160h-16C291.6 160 288 156.4 288 152V128H160v24C160 156.4 156.4 160 152 160h-16C131.6 160 128 156.4 128 152V112C128 103.1 135.1 96 144 96h160C312.9 96 320 103.1 320 112V152z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("span", [_vm._v(" Document Center ")]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$hasPermissions(
                      _vm.clientSession,
                      ["EMAIL_TEMPLATES"],
                      1
                    )
                      ? _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "EmailTemplates" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "padding-right": "1px" } },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-envelope",
                                      staticStyle: {
                                        "font-size": "0.9rem !important",
                                      },
                                    }),
                                  ]
                                ),
                                _c("span", [_vm._v(" Email Templates ")]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$hasPermissions(_vm.clientSession, ["ANNOUNCEMENTS"], 1)
                      ? _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "Announcements" },
                                  linklinkClass: "has-text-white",
                                },
                              },
                              [
                                _c("span", [
                                  _c("div", { staticClass: "fa-svg" }, [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 512 512",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.75 11.25 15.45 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM232 104C232 90.75 242.8 80 256 80s24 10.75 24 24v112c0 13.25-10.75 24-24 24S232 229.3 232 216V104zM256 336c-17.67 0-32-14.33-32-32c0-17.67 14.33-32 32-32s32 14.33 32 32C288 321.7 273.7 336 256 336z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("span", [_vm._v(" Announcements ")]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.activeLayout == "administrative" &&
                _vm.$hasPermissions(
                  _vm.clientSession,
                  [
                    "EMAIL_TEMPLATES",
                    "CLIENT_REPORTS",
                    "DOCUMENT_CENTER",
                    "ANNOUNCEMENTS",
                    ,
                    "ASSET_REPORT",
                    "ASSET_SUMMARY_REPORT",
                    "C8925_REPORT",
                    "ANNUAL_QUESTIONNAIRE",
                    "ANNUAL_BOLI_REVIEW",
                  ],
                  1
                )
                  ? _c("p", { staticClass: "menu-label" }, [
                      _c("span", [_vm._v("Auditing")]),
                    ])
                  : _vm._e(),
                _c("ul", { staticClass: "menu-list" }, [
                  _c(
                    "li",
                    [
                      _c(
                        "nav-link",
                        {
                          attrs: {
                            to: { name: "ClienExceptionReport" },
                            linkClass: "has-text-white",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "padding-right": "1px" } },
                            [_c("i", { staticClass: "fa fa-file-alt" })]
                          ),
                          _c("span", [_vm._v(" CSV & DB Monthly Validation ")]),
                          _vm.exceptionReportClientException
                            ? _c(
                                "span",
                                {
                                  staticStyle: { color: "yellow" },
                                  attrs: {
                                    title:
                                      "Exception found in the " +
                                      _vm.exceptionReportClientExceptionEOM +
                                      " data.",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-exclamation-triangle",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "nav-link",
                        {
                          attrs: {
                            to: { name: "ClienExceptionReportCashSurrender" },
                            linkClass: "has-text-white",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "padding-right": "1px" } },
                            [_c("i", { staticClass: "fa fa-file-alt" })]
                          ),
                          _c("span", [_vm._v("CSV & DB MoM Balance")]),
                          _vm.exceptionReportClientException
                            ? _c(
                                "span",
                                {
                                  staticStyle: { color: "yellow" },
                                  attrs: {
                                    title:
                                      "Exception found in the " +
                                      _vm.exceptionReportClientCashSurrenderExceptionEOM +
                                      " data.",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-exclamation-triangle",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "nav-link",
                        {
                          attrs: {
                            to: { name: "PolicyCountMatchReport" },
                            linkClass: "has-text-white",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "padding-right": "1px" } },
                            [_c("i", { staticClass: "fa fa-file-alt" })]
                          ),
                          _c("span", [_vm._v(" Policy Reconciliation ")]),
                          _vm.exceptionReportPolicyCountException
                            ? _c(
                                "span",
                                {
                                  staticStyle: { color: "yellow" },
                                  attrs: {
                                    title:
                                      "Exception found in the " +
                                      _vm.exceptionReportPolicyCountExceptionEOM +
                                      " data.",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-exclamation-triangle",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "nav-link",
                        {
                          attrs: {
                            to: { name: "BeginningYearBalanceReport" },
                            linkClass: "has-text-white",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "padding-right": "1px" } },
                            [_c("i", { staticClass: "fa fa-file-alt" })]
                          ),
                          _c("span", [
                            _vm._v(" Beginning Year Balance Comparison "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "nav-link",
                        {
                          attrs: {
                            to: { name: "YieldExceptionsReport" },
                            linkClass: "has-text-white",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "padding-right": "1px" } },
                            [_c("i", { staticClass: "fa fa-file-alt" })]
                          ),
                          _c("span", [_vm._v(" Current Yield Variance ")]),
                          _vm.exceptionReportYieldException
                            ? _c(
                                "span",
                                {
                                  staticStyle: { color: "yellow" },
                                  attrs: {
                                    title:
                                      "Exception found in the " +
                                      _vm.exceptionReportYieldExceptionEOM +
                                      " data.",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-exclamation-triangle",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.activeLayout == "administrative" &&
                _vm.$hasPermissions(
                  _vm.clientSession,
                  [
                    "CARRIERS",
                    "INSTITUTIONS",
                    "REPORT_TYPES",
                    "CLIENT_MENU",
                    "SITES",
                  ],
                  1
                )
                  ? _c("p", { staticClass: "menu-label" }, [
                      _c("span", [_vm._v("Configuration")]),
                    ])
                  : _vm._e(),
                _c(
                  "ul",
                  { staticClass: "menu-list" },
                  [
                    _c(
                      "has-permissions",
                      { attrs: { permissions: [["CARRIERS", 1]] } },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "CarrierManagementScreen" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c("span", [
                                  _c("i", { staticClass: "fas fa-briefcase" }),
                                ]),
                                _c("span", [_vm._v(" Carriers ")]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.$hasPermissions(_vm.clientSession, ["INSTITUTIONS"], 1)
                      ? _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "Institutions" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c("span", [
                                  _c("i", { staticClass: "fa fa-university" }),
                                ]),
                                _c("span", [_vm._v(" Institutions ")]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$hasPermissions(_vm.clientSession, ["REPORT_TYPES"], 1)
                      ? _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "ReportTypes" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c("span", [
                                  _c("i", { staticClass: "fa fa-shapes" }),
                                ]),
                                _c("span", [_vm._v(" Report Types ")]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$hasPermissions(
                      _vm.clientSession,
                      ["REPORT_CONFIGURATION"],
                      1
                    )
                      ? _c("li", { staticClass: "admin-dropdown" }, [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              id: "report-config-dropdown",
                            },
                          }),
                          _vm._m(0),
                          _c("div", { staticClass: "admin-dropdown-content" }, [
                            _c("ul", [
                              _c(
                                "li",
                                [
                                  _c(
                                    "has-permissions",
                                    {
                                      attrs: {
                                        permissions: [
                                          ["ANNUAL_QUESTIONNAIRE", 1],
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "nav-link",
                                        {
                                          attrs: {
                                            route: "report-configuration",
                                            text: "State Config",
                                          },
                                        },
                                        [_c("i", { staticClass: "fa fa-cog" })]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "li",
                                [
                                  _c(
                                    "has-permissions",
                                    {
                                      attrs: {
                                        permissions: [
                                          ["ANNUAL_QUESTIONNAIRE", 1],
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "nav-link",
                                        {
                                          attrs: {
                                            route:
                                              "report-configuration-annual-questionnaire",
                                            text: "Annual Questionnaire",
                                          },
                                        },
                                        [_c("i", { staticClass: "fa fa-cog" })]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "li",
                                [
                                  _c(
                                    "has-permissions",
                                    {
                                      attrs: {
                                        permissions: [
                                          ["ANNUAL_BOLI_REVIEW", 1],
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "nav-link",
                                        {
                                          attrs: {
                                            route:
                                              _vm.coliOrBoli +
                                              "-Review-Configuration",
                                            text:
                                              "Annual " +
                                              _vm.coliOrBoli +
                                              " Review",
                                          },
                                        },
                                        [_c("i", { staticClass: "fa fa-cog" })]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.$hasPermissions(_vm.clientSession, ["CLIENT_MENU"], 1)
                      ? _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "ClientMenuManagement" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c("span", [
                                  _c("i", { staticClass: "fa fa-list-ul" }),
                                ]),
                                _c("span", [_vm._v(" Client Menu ")]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$hasPermissions(_vm.clientSession, ["SITES"], 1)
                      ? _c(
                          "li",
                          [
                            _c(
                              "nav-link",
                              {
                                attrs: {
                                  to: { name: "Sites" },
                                  linkClass: "has-text-white",
                                },
                              },
                              [
                                _c("span", [
                                  _c("i", { staticClass: "fa fa-shield-alt" }),
                                ]),
                                _c("span", [_vm._v(" Sites ")]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.activeLayout == "administrative" &&
                _vm.$hasPermissions(
                  _vm.clientSession,
                  ["PERMISSION_TEMPLATES", "USERS", "ACTIVITY_REPORT"],
                  1
                )
                  ? _c("p", { staticClass: "menu-label" }, [
                      _c("span", [_vm._v("Security")]),
                    ])
                  : _vm._e(),
                _c("ul", { staticClass: "menu-list" }, [
                  _vm.$hasPermissions(
                    _vm.clientSession,
                    ["PERMISSION_TEMPLATES"],
                    1
                  )
                    ? _c(
                        "li",
                        [
                          _c(
                            "nav-link",
                            {
                              attrs: {
                                to: { name: "PermissionTemplate" },
                                linkClass: "has-text-white",
                              },
                            },
                            [
                              _c("span", [
                                _c("i", { staticClass: "fa fa-lock" }),
                              ]),
                              _c("span", [_vm._v(" Permission Templates ")]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$hasPermissions(_vm.clientSession, ["USERS"], 1)
                    ? _c(
                        "li",
                        [
                          _c(
                            "nav-link",
                            {
                              attrs: {
                                to: { name: "Users" },
                                linkClass: "has-text-white",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "padding-right": "3px" } },
                                [_c("i", { staticClass: "fa fa-users" })]
                              ),
                              _c("span", [_vm._v(" Users ")]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$hasPermissions(_vm.clientSession, ["ACTIVITY_REPORT"], 1)
                    ? _c(
                        "li",
                        [
                          _c(
                            "nav-link",
                            {
                              attrs: {
                                to: { name: "ActivityReport" },
                                linkClass: "has-text-white",
                              },
                            },
                            [
                              _c("span", [
                                _c("i", { staticClass: "fa fa-file-contract" }),
                              ]),
                              _c("span", [_vm._v(" Activity Report ")]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm.activeLayout == "administrative"
                  ? _c("p", { staticClass: "menu-label" }, [
                      _c("span", [_vm._v("My Account")]),
                    ])
                  : _vm._e(),
                _c("ul", { staticClass: "menu-list" }, [
                  _c(
                    "li",
                    [
                      _c(
                        "nav-link",
                        {
                          attrs: {
                            to: { name: "MyProfile" },
                            linkClass: "has-text-white",
                          },
                        },
                        [
                          _c("span", [
                            _c("i", { staticClass: "fa fa-user-circle" }),
                          ]),
                          _c("span", [_vm._v(" Profile ")]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "nav-link",
                        {
                          attrs: {
                            to: { name: "Logout" },
                            linkClass: "has-text-white",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: { "padding-left": "2px !important" },
                            },
                            [_c("i", { staticClass: "fa fa-sign-out-alt" })]
                          ),
                          _c("span", [_vm._v(" Logout ")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "admin-dropdown-trigger",
        attrs: { for: "report-config-dropdown" },
      },
      [
        _c("span", [_c("i", { staticClass: "fa fa-wrench" })]),
        _c("span", [_vm._v(" Report Configuration ")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }