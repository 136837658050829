var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _vm.isLoading
        ? _c("div", [_c("Loading")], 1)
        : _c(
            "div",
            {
              staticClass: "flex-container-row",
              attrs: { id: "body-content-area-all" },
            },
            [
              _vm.questionnaires.length > 0
                ? _c("AnnualQuestionnaireAlert", {
                    attrs: { questionnaires: _vm.questionnaires },
                  })
                : _vm._e(),
              _vm.notifications &&
              _vm.notifications.length > 0 &&
              !_vm.isNotificationsClosed
                ? _c("div", { staticClass: "announcements" }, [
                    _c("div", { staticClass: "announcements-title" }, [
                      _vm._v(" Notice: "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "announcements-list" },
                      _vm._l(_vm.notifications, function (notification) {
                        return _c(
                          "div",
                          {
                            key: notification.id,
                            staticClass: "announcements-list-announcement",
                          },
                          [
                            _c("div", [_vm._v("•")]),
                            _c("div", [
                              _vm._v(" " + _vm._s(notification.message)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "announcements-close",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeNotifications.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._m(0)]
                    ),
                  ])
                : _vm._e(),
              _vm.showRequestModel
                ? _c(
                    "div",
                    {
                      staticClass: "modal is-active",
                      staticStyle: { "z-index": "1200" },
                      attrs: { id: "delete-modal" },
                    },
                    [
                      _c("div", {
                        staticClass: "modal-background",
                        on: {
                          click: function ($event) {
                            _vm.showRequestModel = false
                          },
                        },
                      }),
                      _c("div", { staticClass: "modal-card" }, [
                        _c(
                          "header",
                          { staticClass: "modal-card-head has-bg-info" },
                          [
                            _c(
                              "div",
                              { staticClass: "modal-card-title has-bg-info" },
                              [
                                _vm._v(
                                  "Confirm Add " +
                                    _vm._s(_vm.boliOrColiText) +
                                    " Request"
                                ),
                              ]
                            ),
                            _c("a", {
                              staticClass: "delete",
                              attrs: { "aria-label": "close" },
                              on: {
                                click: function ($event) {
                                  _vm.showRequestModel = false
                                },
                              },
                            }),
                          ]
                        ),
                        _c("section", { staticClass: "modal-card-body" }, [
                          _c("div", { staticClass: "content" }, [
                            _vm._v(
                              " Send a request to add " +
                                _vm._s(_vm.boliOrColiText) +
                                " to your account? You will be contacted by a representative to discuss and coordinate the request. "
                            ),
                          ]),
                        ]),
                        _c("footer", { staticClass: "modal-card-foot" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button",
                              on: {
                                click: function ($event) {
                                  _vm.showRequestModel = false
                                },
                              },
                            },
                            [_vm._v("No")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "button is-info",
                              attrs: { disabled: _vm.isLoadingBoliRequest },
                              on: { click: _vm.sendRequest },
                            },
                            [
                              _vm._v(" Yes "),
                              _vm.isLoadingBoliRequest
                                ? _c("spin-loader", {
                                    staticStyle: { "margin-left": ".75rem" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.showBoliChartModal && _vm.canShowCharts
                ? _c(
                    "div",
                    {
                      staticClass: "modal is-active",
                      staticStyle: { "z-index": "1200" },
                      attrs: { id: "delete-modal" },
                    },
                    [
                      _c("div", {
                        staticClass: "modal-background",
                        on: {
                          click: function ($event) {
                            _vm.showBoliChartModal = false
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "modal-card",
                          staticStyle: { height: "75vh", width: "50vw" },
                        },
                        [
                          _c("header", { staticClass: "modal-card-head" }, [
                            _c("div", { staticClass: "modal-card-title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.boliOrColiText) +
                                  " Assets & Capacity "
                              ),
                              _c("small", [
                                _vm._v(
                                  "(In $" +
                                    _vm._s(
                                      _vm.chartdata.divisor > 1000
                                        ? "100,000"
                                        : "1,000"
                                    ) +
                                    "'s)"
                                ),
                              ]),
                            ]),
                            _c("a", {
                              staticClass: "delete",
                              attrs: { "aria-label": "close" },
                              on: {
                                click: function ($event) {
                                  _vm.showBoliChartModal = false
                                },
                              },
                            }),
                          ]),
                          _c(
                            "section",
                            {
                              staticClass: "modal-card-body",
                              staticStyle: {
                                "border-bottom-left-radius": "6px",
                                "border-bottom-right-radius": "6px",
                                height: "40vh",
                                width: "50vw",
                                overflow: "hidden",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "card-body",
                                  staticStyle: {
                                    height: "85%",
                                    width: "90%",
                                    margin: "0 auto",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "title-group" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "title",
                                        staticStyle: { margin: "0px" },
                                        attrs: { for: "" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.boliOrColiText) +
                                            " % of Allowable Capital "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.chartdata.percentage) +
                                              "%"
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm.activeSite.id === 1
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "title",
                                            attrs: { for: "" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.boliOrColiText) +
                                                " Additional Capacity "
                                            ),
                                            _c("b", [
                                              _vm._v(
                                                "$" +
                                                  _vm._s(
                                                    0 >
                                                      _vm.chartdata
                                                        .totalAvailable /
                                                        _vm.chartdata.divisor
                                                      ? "0.00"
                                                      : _vm.formatMoney(
                                                          (
                                                            _vm.chartdata
                                                              .totalAvailable /
                                                            _vm.chartdata
                                                              .divisor
                                                          ).toFixed(0),
                                                          false
                                                        )
                                                  )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chart-container",
                                      staticStyle: {
                                        "max-height": "calc(100% - 50px)",
                                        "max-width": "calc(100% - 50px)",
                                        "aspect-ratio": "1",
                                      },
                                    },
                                    [
                                      _c("DoughnutChart", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: {
                                          chartdata: _vm.chartdata,
                                          options: _vm.options,
                                          divisor: _vm.chartdata.divisor,
                                          isExpanded: _vm.showBoliChartModal,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("legend", { ref: "legend" }, [
                                    _c("div", { staticClass: "group" }, [
                                      _c("div", {
                                        staticClass: "boli",
                                        attrs: { id: "boli key" },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "boli key" } },
                                        [_vm._v(_vm._s(_vm.boliOrColiText))]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "group" }, [
                                      _c("div", {
                                        staticClass: "boli-add-capacity",
                                        attrs: { id: "boli add capacity key" },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "boli add capacity key",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.boliOrColiText) +
                                              " Add Capacity"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("label", { staticClass: "foot-note" }, [
                                    _vm._v(
                                      " As of " +
                                        _vm._s(_vm.chartdata.callReportDate) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.showAssetSummary
                ? _c(
                    "div",
                    {
                      staticClass: "modal is-active",
                      staticStyle: { "z-index": "1200" },
                      attrs: { id: "delete-modal" },
                    },
                    [
                      _c("div", {
                        staticClass: "modal-background",
                        on: {
                          click: function ($event) {
                            _vm.showAssetSummary = false
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "modal-card",
                          staticStyle: {
                            height: "75vh",
                            "min-width": "50vw",
                            "max-width": "90vw",
                            width: "100%",
                          },
                        },
                        [
                          _c("header", { staticClass: "modal-card-head" }, [
                            _c("div", { staticClass: "modal-card-title" }, [
                              _vm._v("Asset Summary "),
                              _c("small", [
                                _vm._v(
                                  "(In $" +
                                    _vm._s(
                                      _vm.chartdata.divisor > 1000
                                        ? "100,000"
                                        : "1,000"
                                    ) +
                                    "'s)"
                                ),
                              ]),
                            ]),
                            _c("a", {
                              staticClass: "delete",
                              attrs: { "aria-label": "close" },
                              on: {
                                click: function ($event) {
                                  _vm.showAssetSummary = false
                                },
                              },
                            }),
                          ]),
                          _c(
                            "section",
                            {
                              staticClass: "modal-card-body",
                              staticStyle: {
                                "border-bottom-left-radius": "6px",
                                "border-bottom-right-radius": "6px",
                                height: "40vh",
                                width: "100%",
                                overflow: "scroll",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "card-body",
                                  staticStyle: {
                                    height: "85%",
                                    width: "90%",
                                    margin: "0 auto",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.dashboardData.length > 0,
                                          expression:
                                            "dashboardData.length > 0",
                                        },
                                      ],
                                      staticClass: "asset-summary",
                                    },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticClass: "table asset-summary",
                                          staticStyle: { width: "100%" },
                                        },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c("th", [_vm._v("Carrier")]),
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right",
                                                  },
                                                },
                                                [_vm._v("%")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.findStartDate())
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.findEndDate())
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right",
                                                  },
                                                },
                                                [_vm._v("Current Yield")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    "text-align":
                                                      "right!important",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.findTaxRate()) +
                                                      "% Tax "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(" Equivalent"),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "tbody",
                                            [
                                              _c(
                                                "tr",
                                                { staticClass: "totals" },
                                                [
                                                  _c("td", [_vm._v("Totals")]),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "right",
                                                      },
                                                    },
                                                    [_vm._v("100.00%")]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "right",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "$" +
                                                          _vm._s(
                                                            _vm.formatMoney(
                                                              _vm
                                                                .getTotals(
                                                                  _vm.dashboardData,
                                                                  "beginningCashValue",
                                                                  _vm.chartdata
                                                                    .divisor
                                                                )
                                                                .toFixed(0),
                                                              false
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "right",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "$" +
                                                          _vm._s(
                                                            _vm.formatMoney(
                                                              _vm
                                                                .getTotals(
                                                                  _vm.dashboardData,
                                                                  "endingCashValue",
                                                                  _vm.chartdata
                                                                    .divisor
                                                                )
                                                                .toFixed(0),
                                                              false
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "right",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          (
                                                            (_vm.getTotals(
                                                              _vm.dashboardData,
                                                              "sumCurrentYield"
                                                            ) *
                                                              100) /
                                                            _vm.dashboardData
                                                              .length
                                                          ).toFixed(2)
                                                        ) + "%"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "right",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          (
                                                            (_vm.getTotals(
                                                              _vm.dashboardData,
                                                              "sumCurrentYieldTaxEquivalent"
                                                            ) *
                                                              100) /
                                                            _vm.dashboardData
                                                              .length
                                                          ).toFixed(2)
                                                        ) + "%"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.dashboardData,
                                                function (asset) {
                                                  return _c(
                                                    "tr",
                                                    { key: asset.carrierCode },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "left",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "abbr",
                                                            {
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                              attrs: {
                                                                title:
                                                                  asset.shortName,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  asset.carrierCode
                                                                ) +
                                                                  " - " +
                                                                  _vm._s(
                                                                    asset.shortName
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "right",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                (
                                                                  asset.percentOfTotal *
                                                                  100
                                                                ).toFixed(2)
                                                              ) +
                                                              "% "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "right",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " $" +
                                                              _vm._s(
                                                                _vm.formatMoney(
                                                                  (
                                                                    asset.beginningCashValue /
                                                                    _vm
                                                                      .chartdata
                                                                      .divisor
                                                                  ).toFixed(0),
                                                                  false
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "right",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " $" +
                                                              _vm._s(
                                                                _vm.formatMoney(
                                                                  (
                                                                    asset.endingCashValue /
                                                                    _vm
                                                                      .chartdata
                                                                      .divisor
                                                                  ).toFixed(0),
                                                                  false
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "right",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                (
                                                                  asset.currentYield *
                                                                  100
                                                                ).toFixed(2)
                                                              ) +
                                                              "% "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "right",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                (
                                                                  asset.currentYieldTaxEquivalent *
                                                                  100
                                                                ).toFixed(2)
                                                              ) +
                                                              "% "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.dashboardData.length === 0,
                                          expression:
                                            "dashboardData.length === 0",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.dashboardData.length < 1,
                                              expression:
                                                "dashboardData.length < 1",
                                            },
                                          ],
                                          staticClass:
                                            "is-size-6 has-text-centered has-text-weight-bold",
                                          staticStyle: {
                                            "padding-top": "50px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " No Asset Summary Data For " +
                                              _vm._s(_vm.institution.name) +
                                              ". "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.showCreditChartModal
                ? _c(
                    "div",
                    {
                      staticClass: "modal is-active",
                      staticStyle: { "z-index": "1200" },
                      attrs: { id: "delete-modal" },
                    },
                    [
                      _c("div", {
                        staticClass: "modal-background",
                        on: {
                          click: function ($event) {
                            _vm.showCreditChartModal = false
                            _vm.getCreditChartData(true, function () {
                              return _vm.$forceUpdate()
                            })
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "modal-card",
                          staticStyle: { height: "75vh", width: "90vw" },
                        },
                        [
                          _c("header", { staticClass: "modal-card-head" }, [
                            _c("div", { staticClass: "modal-card-title" }, [
                              _vm._v(" Credit Concentration "),
                              _c("small", [
                                _vm._v(
                                  "(In $" +
                                    _vm._s(
                                      _vm.chartdata.divisor > 1000
                                        ? "100,000"
                                        : "1,000"
                                    ) +
                                    "'s)"
                                ),
                              ]),
                            ]),
                            _c("a", {
                              staticClass: "delete",
                              attrs: { "aria-label": "close" },
                              on: {
                                click: function ($event) {
                                  _vm.showCreditChartModal = false
                                  _vm.getCreditChartData(true, function () {
                                    return _vm.$forceUpdate()
                                  })
                                },
                              },
                            }),
                          ]),
                          _c(
                            "section",
                            {
                              staticClass: "modal-card-body",
                              staticStyle: {
                                "border-bottom-left-radius": "6px",
                                "border-bottom-right-radius": "6px",
                                padding: "0px!important",
                                height: "50vh",
                                width: "100%",
                              },
                            },
                            [
                              _vm.showCreditChart
                                ? _c("credit-concentration-chart", {
                                    ref: "canvas",
                                    staticClass: "chart-container",
                                    staticStyle: {
                                      padding: "5rem 0 0 0px!important",
                                      height: "100%",
                                      "max-height": "100%",
                                    },
                                    attrs: {
                                      chartdata: _vm.creditchartdata,
                                      options: _vm.creditoptions,
                                      showCode: false,
                                    },
                                  })
                                : _c("Loading"),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.canShowCharts
                ? _c(
                    "div",
                    {
                      class: [
                        "flex-container",
                        (_vm.showBoliChart || _vm.showCreditChart) &&
                        _vm.canShowCharts
                          ? "flex-1/3"
                          : "",
                      ],
                    },
                    [
                      _vm.showBoliChart
                        ? _c(
                            "div",
                            {
                              staticClass: "card",
                              staticStyle: {
                                "max-height": "50%",
                                height: "100%",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "has-text-centered heading" },
                                [
                                  _c(
                                    "h1",
                                    {
                                      staticClass: "is-size-6",
                                      staticStyle: {
                                        padding: "10px 0px",
                                        position: "relative",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.boliOrColiText) +
                                          " Assets & Capacity "
                                      ),
                                      _c("small", [
                                        _vm._v(
                                          "(In $" +
                                            _vm._s(
                                              _vm.chartdata.divisor > 1000
                                                ? "100,000"
                                                : "1,000"
                                            ) +
                                            "'s)"
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "expandable-chart",
                                          on: {
                                            click: function ($event) {
                                              _vm.showBoliChartModal = true
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-expand",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "card-body" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "card-chart",
                                    staticStyle: {
                                      height: "100%",
                                      width: "100%",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "title-group" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "title",
                                          staticStyle: { margin: "0px" },
                                          attrs: { for: "" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.boliOrColiText) +
                                              " % of " +
                                              _vm._s(
                                                _vm.activeSite.id === 1
                                                  ? "Allowable Capital"
                                                  : "Net Worth"
                                              ) +
                                              " "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatMoney(
                                                  _vm.chartdata.percentage,
                                                  false
                                                )
                                              ) + "%"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm.activeSite.id === 1
                                        ? _c(
                                            "label",
                                            {
                                              staticClass: "title",
                                              attrs: { for: "" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.boliOrColiText) +
                                                  " Additional Capacity "
                                              ),
                                              _c("b", [
                                                _vm._v(
                                                  "$" +
                                                    _vm._s(
                                                      0 >
                                                        _vm.chartdata
                                                          .totalAvailable /
                                                          _vm.chartdata.divisor
                                                        ? "0.00"
                                                        : _vm.formatMoney(
                                                            (
                                                              _vm.chartdata
                                                                .totalAvailable /
                                                              _vm.chartdata
                                                                .divisor
                                                            ).toFixed(0),
                                                            false
                                                          )
                                                    )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        ref: "doughnutChartContainer",
                                        staticStyle: {
                                          position: "relative",
                                          display: "inline-block",
                                          height: "calc(100% - 50px)",
                                          width: "100%",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showBoliChartModal = true
                                          },
                                        },
                                      },
                                      [
                                        _c("DoughnutChart", {
                                          staticClass: "chart-container",
                                          style: {
                                            height: "100%",
                                            display: "inline-block",
                                            marginBottom: "1rem",
                                            padding: 0,
                                          },
                                          attrs: {
                                            chartdata: _vm.chartdata,
                                            options: _vm.options,
                                            divisor: _vm.chartdata.divisor,
                                            site: _vm.activeSite.id,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          right: "0rem",
                                          bottom: "0",
                                          padding: ".6rem 1rem",
                                          color: "#8e8e8e",
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            ref: "addBOLIButton",
                                            staticClass:
                                              "button is-accent has-text-white add-boli-button",
                                            attrs: { type: "submsit" },
                                            on: {
                                              click: function ($event) {
                                                _vm.showRequestModel = true
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " Add " +
                                                _vm._s(_vm.boliOrColiText) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "legend",
                                      {
                                        ref: "legend",
                                        staticStyle: {
                                          position: "absolute",
                                          width: "70%",
                                          left: "0",
                                          right: "0",
                                          margin: "0 auto",
                                          bottom: "0",
                                          padding: ".35rem 1rem",
                                          color: "#8e8e8e",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "group",
                                            staticStyle: { padding: ".25rem" },
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "boli",
                                              attrs: { id: "boli key" },
                                            }),
                                            _c(
                                              "label",
                                              { attrs: { for: "boli key" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.boliOrColiText)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "group",
                                            staticStyle: { padding: ".25rem" },
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "boli-add-capacity",
                                              attrs: {
                                                id: "boli add capacity key",
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "boli add capacity key",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.boliOrColiText) +
                                                    " Add Capacity"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "add-boli-container",
                                        staticStyle: { display: "none" },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            ref: "addBOLIButton",
                                            staticClass:
                                              "button is-accent has-text-white",
                                            attrs: { type: "submit" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.showRequestModel = true
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " Add " +
                                                _vm._s(_vm.boliOrColiText) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "label",
                                      { staticClass: "foot-note-tile" },
                                      [
                                        _vm._v(
                                          " As of " +
                                            _vm._s(
                                              _vm.chartdata.callReportDate
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.showCreditChart
                        ? _c(
                            "div",
                            {
                              staticClass: "card",
                              staticStyle: {
                                "max-height": "50%",
                                height: "100%",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "has-text-centered heading",
                                  staticStyle: {
                                    "background-color": "#ededed",
                                  },
                                },
                                [
                                  _c(
                                    "h1",
                                    {
                                      staticClass: "is-size-6",
                                      staticStyle: {
                                        padding: "10px 0px",
                                        position: "relative",
                                      },
                                    },
                                    [
                                      _vm._v(" Credit Concentration "),
                                      _c("small", [
                                        _vm._v(
                                          "(In $" +
                                            _vm._s(
                                              _vm.chartdata.divisor > 1000
                                                ? "100,000"
                                                : "1,000"
                                            ) +
                                            "'s)"
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "expandable-chart",
                                          on: {
                                            click: function ($event) {
                                              _vm.showCreditChartModal = true
                                              _vm.getCreditChartData(
                                                false,
                                                function () {
                                                  return _vm.$forceUpdate()
                                                }
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-expand",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "card-body card-chart",
                                  on: {
                                    click: function ($event) {
                                      _vm.showCreditChartModal = true
                                      _vm.getCreditChartData(
                                        false,
                                        function () {
                                          return _vm.$forceUpdate()
                                        }
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.showCreditChart
                                    ? _c("credit-concentration-chart", {
                                        staticClass: "chart-container",
                                        attrs: {
                                          chartdata: _vm.creditchartdata,
                                          options: _vm.creditoptions,
                                          showCode: true,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "flex-container flex-2/3",
                  style: {
                    margin:
                      (_vm.showBoliChart || _vm.showCreditChart) &&
                      _vm.canShowCharts
                        ? ""
                        : "0 auto",
                  },
                },
                [
                  _vm.dashboardData.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "card",
                          staticStyle: { "max-height": "50%", height: "100%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "has-text-centered heading",
                              staticStyle: { "background-color": "#ededed" },
                            },
                            [
                              _c(
                                "h1",
                                {
                                  staticClass: "is-size-6",
                                  staticStyle: { padding: "10px 0px" },
                                },
                                [
                                  _vm._v(" Asset Summary "),
                                  _c("small", [
                                    _vm._v(
                                      "(In $" +
                                        _vm._s(
                                          _vm.chartdata.totalAmount / 1000 >
                                            100000
                                            ? "100,000"
                                            : "1,000"
                                        ) +
                                        "'s)"
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "expandable-chart",
                                      on: {
                                        click: function ($event) {
                                          _vm.showAssetSummary = true
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-expand" })]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "card-body" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.dashboardData.length < 1,
                                    expression: "dashboardData.length < 1",
                                  },
                                ],
                                staticClass:
                                  "is-size-6 has-text-centered has-text-weight-bold",
                                staticStyle: { "padding-top": "50px" },
                              },
                              [
                                _vm._v(
                                  " No Asset Summary Data For " +
                                    _vm._s(_vm.institution.name) +
                                    ". "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.dashboardData.length > 0,
                                    expression: "dashboardData.length > 0",
                                  },
                                ],
                                staticClass: "table-container asset-summary",
                              },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass: "table asset-summary",
                                    staticStyle: { width: "100%" },
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", [_vm._v("Carrier")]),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [_vm._v("%")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.findStartDate()))]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.findEndDate()))]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [_vm._v("Current Yield")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "text-align": "right!important",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.findTaxRate()) +
                                                "% Tax Equivalent"
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      [
                                        _c("tr", { staticClass: "totals" }, [
                                          _c("td", [_vm._v("Totals")]),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                              },
                                            },
                                            [_vm._v("100.00%")]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "$" +
                                                  _vm._s(
                                                    _vm.formatMoney(
                                                      _vm
                                                        .getTotals(
                                                          _vm.dashboardData,
                                                          "beginningCashValue",
                                                          _vm.chartdata.divisor
                                                        )
                                                        .toFixed(0),
                                                      false
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "$" +
                                                  _vm._s(
                                                    _vm.formatMoney(
                                                      _vm
                                                        .getTotals(
                                                          _vm.dashboardData,
                                                          "endingCashValue",
                                                          _vm.chartdata.divisor
                                                        )
                                                        .toFixed(0),
                                                      false
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  (
                                                    (_vm.getTotals(
                                                      _vm.dashboardData,
                                                      "sumCurrentYield"
                                                    ) *
                                                      100) /
                                                    _vm.dashboardData.length
                                                  ).toFixed(2)
                                                ) + "%"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  (
                                                    (_vm.getTotals(
                                                      _vm.dashboardData,
                                                      "sumCurrentYieldTaxEquivalent"
                                                    ) *
                                                      100) /
                                                    _vm.dashboardData.length
                                                  ).toFixed(2)
                                                ) + "%"
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._l(
                                          _vm.dashboardData,
                                          function (asset) {
                                            return _c(
                                              "tr",
                                              { key: asset.CarrierCode },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "abbr",
                                                      {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        attrs: {
                                                          title:
                                                            asset.shortName,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            asset.carrierCode
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "right",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          (
                                                            asset.percentOfTotal *
                                                            100
                                                          ).toFixed(2)
                                                        ) +
                                                        "% "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "right",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " $" +
                                                        _vm._s(
                                                          _vm.formatMoney(
                                                            (
                                                              asset.beginningCashValue /
                                                              _vm.chartdata
                                                                .divisor
                                                            ).toFixed(0),
                                                            false
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "right",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " $" +
                                                        _vm._s(
                                                          _vm.formatMoney(
                                                            (
                                                              asset.endingCashValue /
                                                              _vm.chartdata
                                                                .divisor
                                                            ).toFixed(0),
                                                            false
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "right",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          (
                                                            asset.currentYield *
                                                            100
                                                          ).toFixed(2)
                                                        ) +
                                                        "% "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "right",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          (
                                                            asset.currentYieldTaxEquivalent *
                                                            100
                                                          ).toFixed(2)
                                                        ) +
                                                        "% "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "card",
                      staticStyle: { "max-height": "50%", height: "100%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "has-text-centered heading",
                          staticStyle: {
                            "background-color": "#ededed",
                            margin: "0",
                          },
                        },
                        [
                          _c(
                            "h1",
                            {
                              staticClass: "is-size-6",
                              staticStyle: { padding: "10px 0px" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.pageheading.toLocaleUpperCase()) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "card-body" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.reports.length < 1,
                                expression: "reports.length < 1",
                              },
                            ],
                            staticClass:
                              "is-size-6 has-text-centered has-text-weight-bold",
                            staticStyle: { "padding-top": "50px" },
                          },
                          [
                            _vm._v(
                              " No Reports recent reports for " +
                                _vm._s(_vm.institution.name) +
                                ". "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.reports.length > 0,
                                expression: "reports.length > 0",
                              },
                            ],
                            staticClass: "table-container",
                          },
                          [
                            _c(
                              "table",
                              {
                                staticClass: "table",
                                staticStyle: { width: "100%" },
                              },
                              [
                                _vm._m(1),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.reports, function (report) {
                                    return _c("tr", { key: report.id }, [
                                      _c(
                                        "td",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.getReport(report)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "abbr",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              attrs: {
                                                title: report.displayName,
                                              },
                                            },
                                            [_vm._v(_vm._s(report.displayName))]
                                          ),
                                        ]
                                      ),
                                      _vm.getReportDateFromString(
                                        report.displayName
                                      ) !== ""
                                        ? _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getReportDateFromString(
                                                    report.displayName
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    report.uploadDate,
                                                    "timezone",
                                                    "Europe/Saratov",
                                                    "MM/DD/YYYY"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("moment")(
                                                report.uploadDate,
                                                "timezone",
                                                "Europe/Saratov",
                                                "MM/DD/YYYY"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: report.path,
                                              download: "",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-download",
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "fa fa-times" })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Recently Uploaded Documents")]),
        _c("th", [_vm._v("Report Date")]),
        _c("th", [_vm._v("Upload Date")]),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }